 /*Hide scrollbar for Chrome, Safari, and Opera*/
.userDropdown-options::-webkit-scrollbar{
  display: none;
}
 /* UserDropdown.css */
.custom-userDropdown {
  position: relative;
  color: #868585;
  color: black;
  width: 100%;
}

.userDropdown-header {
  background-color: transparent;
  font-size: 100%;
  font-weight: bold;
  padding: 10px;
  padding-left: 15px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 35px;
}
.userDropdown-header:hover {
  background-color: lightgray;
}

.userDropdown-options {
  background-color: rgb(226, 235, 243);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 35px;
  /*Hide scrollbar*/
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.userDropdown-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.userDropdown-option:hover {
  background-color: lightgray;
}

.userDropdown-option.selected {
  font-weight: bold;
}