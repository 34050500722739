.search-bar-container{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.search-bar{
    width: 50%;
}
.form-control{
    width: 100%;
    height: 25px;
    border-radius: 15px;
    border: 1px solid #3a3535;
    background-color: rgb(250, 249, 249);
    text-align: center;
}